import React, { useEffect, useRef, useState } from "react";
import "react-video-seek-slider/styles.css";
import { Box, CircularProgress } from "@mui/material";
import "../Video/Video.css";
import RecordingTabs from "../Tabs";
import '@vidstack/react/player/styles/base.css';
import {
  getAOSRecording,
  getChapters,
  videoLogFileSeekTime,
} from "../../services/api/subject";
import { getToken } from "../../services/api/login";
import { usePreviousValue } from "../../store/hooks";
import { formatDate } from "../../utils/utilfns";
import { MediaPlayer, MediaProvider, Poster, Title, useMediaStore } from '@vidstack/react';
import styles from './player.module.css';
import { VideoLayout } from "../videostack-react/components/layouts/video-layout";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import withErrorBoundary from "../../utils/withErrorBoundary";
// import CustomMediaPlayer from "./CustomPlayer";

interface AOS_Material_Props {
  aos_id: string;
  title: string;
  topic_id: number;
  subject_id: string;
  selectedTopic?: any;
  selectedModuleTypeId?: any;
  setBugRepId: React.Dispatch<React.SetStateAction<string>>;
  setRecordingData?: React.Dispatch<React.SetStateAction<any[]>>;
  setMarkComplete?: any;
  setMarkTaskId?: React.Dispatch<React.SetStateAction<string>>;
  file_id?: any;
  bugRepId?: any;
  selectedType?: string | undefined;
  subjectAOSDetails?: any[];
  open: any;
  openBottomSheet: any;
  bottomDrawerOpen2: any;
  pageSelected?: any;
}
const StudentVideoV1: React.FC<AOS_Material_Props> = ({
  aos_id,
  // topic_id,
  subject_id,
  selectedTopic,
  selectedModuleTypeId,
  setBugRepId,
  title,
  setRecordingData,
  setMarkComplete,
  setMarkTaskId,
  file_id,
  selectedType,
  pageSelected,
}) => {
  const verifyAccess = getToken();
  const previousValue = usePreviousValue(file_id);
  const playerRef = useRef<any>(null);
  const [aosRecordingDetails, setAOSRecordingDetails] = useState<any>([]);
  const [videDetails, setVideDetails] = useState<{ nextVideo: { title: string, filename: string, thumnail: string }, prevVideo: { title: string, filename: string, thumnail: string } } | null>(null);
  const [videoChapters, setVideoChapters] = useState<any>([]);
  const [videoSrc, setVideoSrc] = useState<string>("");
  const [vttSrc, setVttSrc] = useState<string>("");
  const [bugFileId, setFileRepId] = useState(file_id || "");
  const [thumbVideo, setThumbVideo] = useState("");
  const [currentIndex, setCurrentIndex] = useState<any>();
  const { fullscreen: isFullScreen } = useMediaStore(playerRef);
  const [aspectRatio, setAspectRatio] = useState(9 / 6);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("video component mount", previousValue);
    const currenttime = formatDate();
    return () => {
      const endtime = formatDate();
      const time = moment.utc(playerRef.current.currentTime * 1000).format('mm:ss')
      videoLogFileSeekTime({ file_id: file_id, time: time, currenttime, endtime }, verifyAccess.token);
      console.log("video component unmount");
    };
  }, [file_id]);

  useEffect(() => {
    getSubjectAOSRecording(subject_id, aos_id, selectedType);
  }, [subject_id, aos_id, selectedType]);

  useEffect(() => {
    getVideoChapters(bugFileId);
  }, [bugFileId]);

  const getSubjectAOSRecording = async (subject_id: any, aos_id: any, selectedType: any) => {
    try {
      const payload = {
        subject_id: subject_id,
        aos_id: aos_id,
        file_type: selectedType,
      };
      const response = await getAOSRecording(payload, verifyAccess.token);
      setAOSRecordingDetails(response?.data?.data);
      console.log("response?.data?.data", response?.data?.data)
      setCurrentIndex(response?.data?.data.findIndex((va: any) => parseInt(va.file_id) === parseInt(file_id)) ?? 0)

    } catch (error) {
      console.error("Error fetching subject aos details by ID:", error);
    }
  };
  useEffect(() => {
    function getNextAndPrevious(array: Array<any>, currentIndex: number) {
      const arrayLength = array.length;
      const nextIndex = (currentIndex + 1) % arrayLength;
      const previousIndex = (currentIndex - 1 + arrayLength) % arrayLength;
      return {
        next: nextIndex === currentIndex ? undefined : array[nextIndex],
        previous: previousIndex === currentIndex ? undefined : array[previousIndex]
      };
    }
    if (typeof currentIndex !== "undefined" && aosRecordingDetails) {
      const nextprevData = { ...videDetails }
      const { next, previous } = getNextAndPrevious(aosRecordingDetails, currentIndex);

      if (next) {
        const { file_name = "", title = "", thumbnail_url = "" } = next || {};
        nextprevData.nextVideo = { filename: file_name, title: title, thumnail: `${process.env.REACT_APP_CDN}/${thumbnail_url}` }
      }
      if (previous) {
        const { file_name = "", title = "", thumbnail_url = "" } = previous || {};
        nextprevData.prevVideo = { filename: file_name, title: title, thumnail: `${process.env.REACT_APP_CDN}/${thumbnail_url}` }
      }
      console.log("nextprevdata", nextprevData)
      setVideDetails(nextprevData as any)
    }
  }, [currentIndex, aosRecordingDetails]);

  const getVideoChapters = async (fileId: any) => {
    try {
      const response = await getChapters(verifyAccess.token, fileId);
      setVideoChapters(response?.data?.data);
    } catch (error) {
      console.error("Error fetching subject aos details by ID:", error);
    }
  };

  const navigate = useNavigate();

  const handlePrevNext = (videDetails: any) => {
    const { filename = "" } = videDetails || {}
    if (filename !== "") {
      const currentIndex = aosRecordingDetails.findIndex((va: any) => va.file_name === filename)
      const prevSubject = aosRecordingDetails[currentIndex] || {};
      console.log("nextSubject", prevSubject);
      const newSearch = new URLSearchParams();
      newSearch.set("sid", prevSubject.subject_id);
      newSearch.set("aosID", prevSubject.area_of_study_id);
      newSearch.set("topicID", prevSubject.topic_id);
      newSearch.set("typeID", prevSubject.file_type_id);
      newSearch.set("fileID", prevSubject.file_id);
      setCurrentIndex(currentIndex);
      navigate(`/aos?${newSearch.toString()}`);
    }
  };

  const handleSeekTime = (time: number) => {
    if (!playerRef.current?.currentTime) {
      return;
    }
    playerRef.current.currentTime = time;
  }

  useEffect(() => {
    const handleResize = () => {
      if (playerRef.current) {
        const newAspectRatio = 16 / 9; // Default aspect ratio
        setAspectRatio(newAspectRatio);
      }
    };

    handleResize(); // Set initial aspect ratio

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);

    };
  }, []);
  return (
    <Box>
      <Box
        id="videoSec3"
        sx={{ display: { md: "block", sm: "block", xs: "block" } }}
      >
        <Box
          sx={{
            position: "relative !important",
            borderRadius: !isFullScreen ? "17px" : "0px",
          }}
        >

          <MediaPlayer
            title={title}
            src={videoSrc}
            ref={playerRef}
            className={styles.player}
            crossOrigin={"anonymous"}
            style={{ background: !isFullScreen ? "#000" : "#2B3258", position: "relative" }}
            playsInline
            onError={(e:any) => console.error("Media Error:", e?.detail, "Context:", e?.detail.context)}

            onCanPlay={() => setLoading(false)}
          >
            {loading &&
              <Box sx={{ display: 'flex', justifyContent: "center", position: "absolute", zIndex: "100", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                <CircularProgress size={80} color="inherit" />
              </Box>
            }
            {isFullScreen && <Title style={{ position: "absolute", margin: "30px", top: "10px", fontFamily: "Inter", fontWeight: "600", fontSize: "16px", lineHeight: "24px" }} />}
            <MediaProvider style={{ height: !isFullScreen ? "525px" : "calc(100vh - 200px)", aspectRatio: aspectRatio, background: !isFullScreen ? "#000" : "#2B3258" }}>
              <Poster
                className={styles.poster}
                src={`${process.env.REACT_APP_CDN}/${thumbVideo}`}
                crossOrigin={'anonymous'}
                alt="poster"
              />

            </MediaProvider>
            {videDetails !== null && <VideoLayout
              nextprevvideDetails={videDetails}
              playNextVideo={() => handlePrevNext(videDetails.nextVideo)}
              playPrevVideo={() => handlePrevNext(videDetails.prevVideo)}
              thumbnails={`${process.env.REACT_APP_CDN}/${vttSrc}`} />}
          </MediaPlayer>
        </Box>
      </Box>
      <Box sx={{ display: { md: "block", sm: "block", xs: "block" } }}>
        <RecordingTabs
          seekToVideo={(time: number) => handleSeekTime(time)}
          chapters={videoChapters}
          setBugRepId={setBugRepId}
          setFileRepId={setFileRepId}
          aosRelatedDetails={[]}
          chaptersRecordings={aosRecordingDetails}
          onVideoClick={(value) => setVideoSrc(value)}
          aos_id={aos_id}
          subject_id={subject_id}
          selectedTopic={selectedTopic}
          selectedModuleTypeId={selectedModuleTypeId}
          setRecordingData={setRecordingData}
          setMarkComplete={setMarkComplete}
          setMarkTaskId={setMarkTaskId}
          file_id={file_id}
          videoid={bugFileId}
          setThumbVideo={setThumbVideo}
          setVttSrc={setVttSrc}
          selectedPage={pageSelected}
        />
      </Box>
    </Box>
  );
};

export default withErrorBoundary(StudentVideoV1, <>Something went wrong with StudentVideoV1 Component</>);
